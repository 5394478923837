export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/opt/buildhome/repo/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/opt/buildhome/repo/src/pages/[...all].vue'),
    /* no children */
  },
  {
    path: '/auth',
    name: '/auth',
    component: () => import('/opt/buildhome/repo/src/pages/auth.vue'),
    children: [
      {
        path: 'debox',
        /* internal name: '/auth/debox' */
        /* no component */
        children: [
          {
            path: 'callback',
            name: '/auth/debox/callback',
            component: () => import('/opt/buildhome/repo/src/pages/auth/debox/callback.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'google',
        /* internal name: '/auth/google' */
        /* no component */
        children: [
          {
            path: 'callback',
            name: '/auth/google/callback',
            component: () => import('/opt/buildhome/repo/src/pages/auth/google/callback.vue'),
            /* no children */
          }
        ],
      }
    ],
    meta: {
      "layout": "auth-callback"
    }
  },
  {
    path: '/farm',
    /* internal name: '/farm' */
    /* no component */
    children: [
      {
        path: '',
        name: '/farm/',
        component: () => import('/opt/buildhome/repo/src/pages/farm/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/farm/[id]',
        component: () => import('/opt/buildhome/repo/src/pages/farm/[id].vue'),
        /* no children */
        meta: {
          "layout": "user-farm"
        }
      }
    ],
  },
  {
    path: '/login',
    name: '/login',
    component: () => import('/opt/buildhome/repo/src/pages/login.vue'),
    /* no children */
    meta: {
      "layout": "login"
    }
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

