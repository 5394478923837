import type { App } from 'vue'
import { createAppKit } from '@reown/appkit'
import { type AppKitNetwork, mainnet } from '@reown/appkit/networks'
import { getAppKit } from '@reown/appkit/vue'
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi'
import { cookieStorage, createStorage, injected } from '@wagmi/core'
import { WagmiPlugin } from '@wagmi/vue'

// 1. Your Reown Cloud project ID
const projectId = import.meta.env.VITE_REOWN_PROJECT_ID

if (!projectId) {
  throw new Error('Project ID is not defined')
}

export const networks: [AppKitNetwork, ...AppKitNetwork[]] = [mainnet]

const metadata = {
  name: 'DeBox Guardians Miner',
  description: 'DeBox Game App Guardians Miner',
  url: window.location.origin, // origin must match your domain & subdomain
  icons: [`${window.location.origin}/favicon.png`],
}

const wagmiAdapter = new WagmiAdapter({
  storage: createStorage({
    storage: cookieStorage,
  }),
  connectors: [injected()],
  multiInjectedProviderDiscovery: true,
  projectId,
  networks,
})

export const wagmiConfig = wagmiAdapter.wagmiConfig

export function install(app: App) {
  const appKit = createAppKit({
    adapters: [wagmiAdapter],
    networks,
    metadata,
    projectId,
    // enableEIP6963: true,
    features: {
      email: false,
      socials: false,
      swaps: false,
      onramp: false,
    },
    // siweConfig: createSIWEConfig({
    //   async getMessageParams() {
    //     return {
    //       domain: window.location.origin,
    //       uri: window.location.origin,
    //       chains: wagmiConfig.chains.map(chain => chain.id),
    //     }
    //   },
    //   createMessage({ address, ...args }) {
    //     localStorage.setItem('siwe-address', address)
    //     localStorage.setItem('siwe-chainId', args.chainId.toString())
    //     return formatMessage(args, address)
    //   },
    //   async getSession() {
    //     if (localStorage.getItem('siwe-address')) {
    //       return {
    //         address: localStorage.getItem('siwe-address') as string,
    //         chainId: Number(localStorage.getItem('siwe-chainId')),
    //       }
    //     }
    //     return null
    //   },
    //   async getNonce(address) {
    //     const { data: { nonce } } = await client<ApiResult<{ nonce: string }>>('/api/user/walletLogin', {
    //       method: 'POST',
    //       body: {
    //         wallet: address,
    //       },
    //     })
    //     return nonce
    //   },
    //   verifyMessage: ({ message, signature }) => fromAsyncThrowable(async () => {
    //     console.info('📔 Verify message', message, signature)
    //     const siwe = new SiweMessage(message)
    //     const { data } = await client<ApiResult<{ token: string, user: any }>>('/api/user/walletAuth', {
    //       method: 'POST',
    //       body: {
    //         wallet: siwe.address,
    //         signature,
    //         message,
    //         invite_code: sessionStorage.getItem('invite-code'),
    //       },
    //     })
    //     userToken.value = data.token
    //     localStorage.setItem('siwe-address', siwe.address)
    //     localStorage.setItem('siwe-chainId', siwe.chainId.toString())
    //   })().match(() => true, () => true),
    //   async signOut() {
    //     return true
    //   },
    //   onSignIn() {
    //     if (router.currentRoute.value.path === '/login') {
    //       router.replace('/')
    //     }
    //   },
    // }),
  })

  getAppKit(appKit)

  console.info('📔 Create app kit', appKit)

  app.use(WagmiPlugin, { config: wagmiConfig })
}
